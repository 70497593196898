.contactUsWrapper {
    /* padding: 16px; */
    width: 100%;
}

.contactButton,
.contactButton:hover,
.contactButton:focus {
    width: 100%;
    background-color: var(--primary);
    color: var(--white);
    font-weight: bold;
    border-radius: 100px;
    border: none;
}

.contactUsText {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
}
.modalHeaderC {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/images/contactUs.webp') no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--white) !important;
    font-weight: bold;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}