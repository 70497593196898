.usaLeaguesWrapper {
    margin-top: -840px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 128px ;
    gap: 16px;
}

.usaLeagueContainer {
    width: 250px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.usaLeagueImgContainer {
    padding: 10px !important;
    border-right: 1px solid var(--orange);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usaLeagueContentContainer {
    padding: 8px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
}

.usaLeagueText {
    font-weight: bold;
    color: var(--orange);
    padding: 0px;
    margin: 0px;
}

@media screen and (max-width:1600px) {
    .usaLeaguesWrapper {
        margin-top: -740px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding-right: 128px ;
        gap: 8px;
    }
}