.depositeWrapper {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.depositeContainer {
    padding: 16px;
    width: 100%;
}

.depositeTitle {
    color: var(--primary);
    font-weight: bold;
}

.depositeAmountContainer p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}

.inputDisabled {
    height: 45px;
    width: 100%;
    font-size: 18px;
    padding-left: 16px;
    color: black;
    border: 1px solid #c4c4c4;
    border-radius: 100px;
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    border: none !important;
    background-color: var(--primary) !important;
    color: var(--white);
    font-weight: bold;
    padding-top: 12px !important;
    padding: 8px 0px;
}

.modalHeaderD {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../../../assets/images/deposit.webp') no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--white) !important;
    font-weight: bold;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:992px) {
    .depositImageContainer {
        display: none;
    }
}