.historiesContainer {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.historyTableHead {
    background-color: var(--primary);
    color: var(--white);
}

.historiesContainer::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width:992px) {
    .historyImageContainer {
        display: none;
    }

    .historyHeader {
        height: 20vh;
    }
}

.modalHeader {
}

.modalHeader h2 {
    color: var(--white) !important;
    font-weight: bold;
}

.titleBackgroundH {
    background: linear-gradient(45deg, rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('../../../../assets/images/history.webp') no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--white) !important;
    font-weight: bold;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}

