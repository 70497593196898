.transactionsContainer {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.transactionTableHead {
    background-color: var(--primary);
    color: var(--white);
}

.transactionsContainer::-webkit-scrollbar {
    display: none;
}

.transactionsInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transactionSentence {
    color: var(--purple);
    font-weight: bold;
}

.modalHeaderT {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../../../assets/images/transaction.webp') no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--white) !important;
    font-weight: bold;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:992px) {
    .transactionImageContainer {
        display: none;
    }
}