.reviews-container {
    padding: 20px;
    background-color: var(--primary);
    border-radius: 10px;
    margin: 20px auto;
    max-width: 1200px;
}

.reviews-header {
    color:var(--secondary);
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.review-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    overflow-x: auto;
}

.review-col {
    flex: 1 1 30%;
    max-width: 30%;
    box-sizing: border-box;
    margin: 10px;
}

.review-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
    background-color: var(--secondary);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.review-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.card-body {
    padding: 20px;
}

.card-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.icon-container {
    border: 2px solid var(--primary);   
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.card-title {
    margin: 0;
    color: var(--primary);
    font-weight: bold;
}

.card-text {
    color: var(--primary);  
    font-style: italic;
    margin-bottom: 15px;
}

.card-date {
    color: var(--third);
    margin-bottom: 10px;
}

.text-muted {
    font-size: 0.9rem;
}

.card-rating {
    color: var(--orange);
    font-size: 1.2rem;
}

.star {
    color: var(--orange);
}
        