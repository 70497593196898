.card {
    overflow: visible;
    background-color: transparent;
    width: 250px;
    height: 500px;
  }
 
  .NewsWrapper {
    margin-top:32px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    padding: 0 16px;
    margin-bottom: 32px;
    justify-content: center; 
    }

  .content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
  }
  
  .front, .back {
    background-color: var(--primary);
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .back {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .back::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 160px;
    height: 160%;
    background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
    animation: rotation_481 5000ms infinite linear;
  }
  
  .bg-image{
    width: 100%;
    height: 50%;
  }

  .newsNumb{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .back-content {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: var(--primary);
    border-radius: 5px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .card:hover .content {
    transform: rotateY(180deg);
  }
  
  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }
  
  .front {
    transform: rotateY(180deg);
    color: var(--secondary);
  }
  
  .front .front-content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .badge {
    font-size: 1.2em;
    padding: 5px 20px !important; 
    height: auto !important;
    display: inline-block; 
}
  
  .front-content .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    color:var(--primary);
    padding: 2px 10px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
  }
  
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; 
}
  .description p {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    width: 100%; 
  }

.description {
    box-shadow: 0px 0px 10px 5px #00000088;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #00000099;
    backdrop-filter: blur(5px);
    border-radius: 5px;
    height: 90%; 
}

  .title {
    font-size: 14px;
    max-width: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .front-content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-footer {
    color: #ffffff88;
    margin-top: auto; 
    font-size: 16px;
    width: 100%; 
}

  .front .img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #ffbb66;
    position: relative;
    filter: blur(15px);
    animation: floating 2600ms infinite linear;
  }
  
  #bottom {
    background-color: #ff8866;
    left: 50px;
    top: 0px;
    width: 150px;
    height: 150px;
    animation-delay: -800ms;
  }
  
  #right {
    background-color: #ff2233;
    left: 160px;
    top: -80px;
    width: 30px;
    height: 30px;
    animation-delay: -1800ms;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0px);
    }
  
    50% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }