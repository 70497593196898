.highlights-wrapper {
    padding: 20px 40px;
    background-color: var(--primary); ;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 1200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.highlights-title-container h2 {
    color: var(--secondary); ;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.loading-spinner {
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spinner .spinner-grow {
    background-color: #C6771F;
}

.highlights-content-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin-top: 20px;
}

.highlights-content {
    background-color: var(--third);
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.highlights-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
