.profileFieldsWrapper {
    padding: 32px 0px;
    width: 100%;
}

.inputLabel {
    color: var(--primary);
    font-size: 16px;
    font-weight: bold;
}

.input,
.input:focus,
.inputD {
    height: 45px;
    border-radius: 100px;
}

.editSubmitButton {
    width: 100% !important;
    background-color: var(--primary) !important;
    border: none !important;
    padding: 8px !important;
    border-radius: 100px !important;
    font-weight: bold !important;
    color: var(--white) !important;
}

.modalHeader {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../../../assets/images/profile.webp') no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--white) !important;
    font-weight: bold;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:992px) {
    .ProfileImageContainer {
        display: none;
    }
}