.nbaRankingWrapper {
    background-color: var(--third);
    padding: 20px;
    border-radius: 10px;
}

.nbaRankingText h4 {
    color: var(--secondary);
    font-weight: bold !important;
    font-size: 28px; /* Increased from 24px */
    text-align: center;
}

.nbaRankingContainer {
    margin-bottom: 16px;
    color: var(--secondary); 
    margin-top: 16px;
    padding: 16px;
    background: var(--primary);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.nbaRankingContainer h5 {
    color: var(--secondary);
    font-weight: bold;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 8px;
    font-size: 24px; /* Increased from 20px */
}

.nbaRankingGamesContainer {
    display: flex;
    flex-direction: column;
}

.nbaRankingGames {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.nbaRankingGamesRank {
    background: var(--third);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
}

.nbaRankingGamesRank p {
    margin: 0;
    padding: 0;
    color: var(--primary);
    font-weight: bold;
    font-size: 20px; /* Increased font size */
}

.nbaRankingGamesImageContainer {
    margin-left: 8px;
}

.nbaRankingGamesNameContainer {
    margin-left: 8px;
}

.nbaRankingGamesNameContainer p {
    margin: 0;
    font-size: 18px; /* Increased from 16px */
}

.nbaRankingGamesWinLossContainer {
    margin-left: 8px;
}

.nbaRankingGamesWinLossContainer p {
    margin: 0;
    font-size: 18px; /* Increased from 16px */
}

.nbaRankingGamesWinLossContainer p b {
    color: var(--primary);
    font-weight: bold;
}

.nbaRankingGamesLast10Container {
    margin-left: 8px;
}

.nbaRankingGamesLast10Container p {
    margin: 0;
    font-size: 18px; /* Increased from 16px */
}
