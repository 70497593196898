.introductionImageWrapper {
    height: 85vh;
    width: 100%;
}

.introductionImage {
    height: 100%;
    width: 100%;
}

@media screen and (max-width:800px) {
    .introductionImageWrapper {
        height: 70vh;
    }
}

@media screen and (max-width:500px) {
    .introductionImageWrapper {
        height: 50vh;
    }
}

@media screen and (max-width:350px) {
    .introductionImageWrapper {
        height: 40vh;
    }
}