/* Base styling for overall consistency and modernity */
body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--secondary);
    color: #333;
}

/* Wrapper styling with a clean, modern background */
.GamesWrapper {
    background-color: var(--third);
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    margin: 20px auto;
    max-width: 1400px;
    padding: 20px;
}

.GamesContent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    color:var(--primary);
    font-weight: bold !important;
}

/* Heading styling for clear visibility */
.GamesText h2 {
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Header container with a subtle shadow for depth */
.GamesHeaderContainer {
    background-color: var(--orange);
    border-radius: 30px;
    display: flex;
    overflow: hidden;
    padding: 5px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
}

/* League selection card styling for a modern, clickable look */
.GamesHeaderCard, .GamesHeaderCardSelected {
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.GamesHeaderCard {
    background-color: var(--orange);
    color: var(--primary);
}

.GamesHeaderCardSelected {
    background-color: var(--third);
}

/* Content container with padding for layout */
.GamesContentContainer {
    margin-top: 20px;
}

/* Game cards container for a responsive layout */
.GamesContentCardsContainer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* Styling for individual game cards */
.GamesContentCard {
    background-color: var(--orange);
    border: 1px solid var(--orange);
    color:var(--primary);
    border-radius: 8px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    padding: 15px;
    transition: transform 0.2s ease-in-out;
}

.GamesContentCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
}

/* Styles for game details for clarity */
.GamesContentCardTeamsContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.GamesContentCardHomeContainer, .GamesContentCardAwayContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
}

/* Score and start time styling for emphasis */
.GamesContentCardScore {
    background-color: var(--primary);
    border-radius: 50%;
    color: var(--orange);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.GamesContentCardStartTime {
    background-color: var(--primary);
    border-radius: 20px;
    color: var(--orange);
    margin-top: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GamesContentCardTicketLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Link styling for accessibility */
.GamesContentCardTicketLink a {
    color: var(--primary);
    text-decoration: none;
    font-weight: bold;
}

/* Message styling for no games */
.noGamesMessage {
    color: #6c757d;
    font-weight: bold;
    text-align: center;
}

