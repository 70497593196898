.withdrawSubmitButton {
    border-radius: 0px !important;
    background-color: var(--primary) !important;
    padding: 8px 0px;
    color: var(--white) !important;
    font-weight: bold;
    border: none;
    width: 100%;
}

.successMessageContainer {
    width: 100%;
    padding: 8px 0px;
}

.successMessageContainer p {
    padding: 8px;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}

.maxButton,
.maxButton:hover,
.maxButton:focus {
    background-color: var(--primary);
    border: none;
    border-radius: 0px;
}

.withdrawInput,
.withdrawInput:focus,
.withdrawInput:hover,
.withdrawInputD {
    height: 45px;
    border-radius: 0px;
}

.withdrawSentence {
    color: var(--purple);
    font-weight: bold;
}

.withdrawLabel {
    font-size: 16px;
    color: var(--primary);
    font-weight: bold;
}

.modalHeaderW {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../../../assets/images/withdraw.webp') no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--white) !important;
    font-weight: bold;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:992px) {
    .withdrawImageContainer {
        display: none;
    }
}