.line{
    width: 45%;
    height: 1px;
    background-color: var(--primary) ;
    display:inline-block;
    margin-bottom: 1vh;
}
.nameTitle {
    padding: 8px;
    color: var(--primary);
    /* font-weight: bold; */
    display:inline-block;
    width:10%;
    font-size: 25px;
    text-align: center;
}
@media (max-width: 1000px) {
    .nameTitle {
        font-size: 20px;
    }
}

@media (max-width: 800px) {
    .line{
        width: 40%;
    }
    .nameTitle {
        width: 20%;
    }
}

@media (max-width: 300px) {
    .nameTitles {
        font-size: 18px;
    }
}
