.nhlLeadersCardContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--primary);
    padding: 20px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    margin: 20px 0;
}

.headerTitle{
    color:var(--primary);
    font-weight: bold;
}

.NhlLeadersText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
}

.count{
    color:var(--third);
    font-weight: bold;
}

.circularProgressContainer {
    width: 150px;
    height: 150px;
}

.nhlLeadersDetails {
    display: flex;
    width:250px;
    height: 260px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: var(--third);;
    border-radius: 20px;
    box-shadow: inset 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.circularProgressContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 250px;
}

.circularProgressContainer .CircularProgressbar .CircularProgressbar-path {
    stroke: var(--third); 
}

.circularProgressContainer .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
}

.circularProgressContainer .CircularProgressbar .CircularProgressbar-background {
    fill: var(--third);;
}

.nhlLeaderHeadshot {
    width: 170px; 
    height: 170px; 
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.number {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    color:var(--primary);
    border: 3px solid var(--primary);
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.nhlLeaderInfo {
    text-align: center;
}

.nhlLeaderInfo h2 {
    font-size: 18px;
    color: var(--primary);;
    margin: 10px 0 5px;
}

.nhlLeaderInfo h3 {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
}

.nhlLeaderInfo p {
    font-size: 14px;
    color: #333;
    margin-top: 5px;
}

.nhlLeadersList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.leaderItem {
    background: var(--third);;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    color: #555;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1);
}

.leaderItem:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.2);
}

.categoryNav {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.categoryButton {
    background-color: var(--orange);
    border: none;
    color: var(--primary); 
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.categoryButton:hover,
.categoryButton.active {
    background-color: var(--primary);
    color:  var(--orange);
}