.navContainer {
    background-color: var(--orange);
    height: auto;
    padding: 0px !important;
}


.navLink2 {
    color: var(--primary) !important;
    font-weight: 500;
    padding-top: 6px;
}



@media (max-width: 762px) {
    .navLink2 {
        margin-left: 16px;
    }

    .upcomingGames {
        margin-left: 16px;
    }

    .navLink22 {
        margin-left: 16px;
    }
}

.hamburgerButton {
    border: none;
    padding: 8px 12px 0px 12px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../assets/images/hamburgerMenu.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}


.sportDropdown {
    color: var(--gray);
    border: none;
    font-weight: 500;
}

.sportDropdown:focus {
    outline: none;
    box-shadow: none;
}

/* .navContainer {
    padding: 10px;
    font-size: 16px;
    padding-left: 24px;
    padding-right: 16px;
    background-color: var(--primary) !important;

} */

@media (max-width:1200px) {
    .navContainer {
        font-size: 14px;
    }
}

.linkContainer {
    text-transform: capitalize;
}


.logo {
    width: 140px;
    height: 35px;
    background-image: url('../../assets/images/securepunters-logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -8px;
}